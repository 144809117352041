body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;


*::-webkit-scrollbar {
  display: none;
}

*{
  transition-duration: color 0.2s, opacity 0.2s;
}

.request-pull-item-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.request-pull-item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s;
}
.request-pull-item-exit {
  opacity: 1;
  transform: translateY(0);
}
.request-pull-item-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.5s;
}

.right-menu-enter{
  opacity: 0;
  transform: translateX(100%);
}
.right-menu-enter-active{
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s;
}
.right-menu-exit{
  opacity: 1;
  transform: translateX(0);
}
.right-menu-exit-active{
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.5s;
}

#sku-label{
  color: #fff;
}

.MuiOutlinedInput-notchedOutline{
  border-color: #fff !important;
}

.MuiInputBase-input{
  color: #fff !important;
}

.scale-up-fade-enter {
  opacity: 0;
  transform: scale(0.5);
  z-index: 9999;
}
.scale-up-fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 0.1s;
  z-index: 9999;
}
.scale-up-fade-exit {
  opacity: 1;
  transform: scale(1);
  z-index: 9999;
}
.scale-up-fade-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.1s;
  z-index: 9999;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: all 0.2s;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: all 0.2s;
}

.right-to-left-fade-enter {
  opacity: 0;
  transform: translateX(100%);
  z-index: 1;
}
.right-to-left-fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.1s;
  z-index: 1;
}
.right-to-left-fade-exit {
  opacity: 1;
  z-index: 1;
  transform: translateX(0);
}
.right-to-left-fade-exit-active {
  opacity: 0;
  z-index: 1;
  transform: translateX(100%);
  transition: all 0.1s;
}

.top-down-fade-enter {
  opacity: 0;
  transform: translateY(-100px);
  z-index: -1;
}
.top-down-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.1s;
  z-index: -1;
}
.top-down-fade-exit {
  opacity: 1;
  z-index: -1;
  transform: translateY(0);
}
.top-down-fade-exit-active {
  opacity: 0;
  z-index: -1;
  transform: translateY(-100px);
  transition: all 0.1s;
}

.MuiToolbar-root, .MuiSvgIcon-root{
  color: #fff;
}